/**
 * External dependencies.
 */
import classNames from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";

interface LinkWithOptionsProp {
  data: {
    URL: string;
    Text: string;
    OpenInNewTab: boolean;
    RelNoFollow: boolean;
  };
  className?: string;
  children?: any;
  onClick?: any;
}

const LinkWithOptions: React.FC<LinkWithOptionsProp> = ({
  data,
  className,
  children,
  onClick,
}) => {
  const { locale, pathname } = useRouter();
  if (!data || !data.URL || !data.Text) {
    return <></>;
  }
  const hrefHandle = data.URL.match(/^\//) ? `/${locale}${data.URL}` : data.URL;

  return (
    <Link
      href={hrefHandle}
      locale={false}
      className={classNames(className, {
        "is-active": pathname === data.URL,
      })}
      target={data.OpenInNewTab ? "_blank" : "_self"}
      rel={data.RelNoFollow ? "nofollow" : ""}
      title={data.Text}
      onClick={onClick}
    >
      {children ? children : data.Text}
    </Link>
  );
};

export default LinkWithOptions;

/**
 * External dependencies
 */
import { useEffect, useRef } from "react";
import { useSpring, animated } from "react-spring";

/**
 * Internal dependencies
 */
import Markdown from "components/markdown/markdown";
import Link from "components/link/link";
import classNames from "classnames";
import { scrollToSection } from "utils";

interface AccordionSectionProps {
  item: any;
  index: number;
  dispatchClick: any;
  currentActiveItem: number | null;
  headingRef: any;
}

const AccordionSection: React.FC<AccordionSectionProps> = ({
  item,
  index,
  dispatchClick,
  currentActiveItem,
  headingRef,
}) => {
  const [bodyStyles, animateBody] = useSpring(() => ({ height: "0px" }), []);
  const [iconStyles, animateIcon] = useSpring(
    () => ({ transform: "rotate(0deg)" }),
    []
  );
  const accordionRef = useRef<any>(null);

  const handleClick = () => {
    dispatchClick();
    // setTimeout(() => {
    //   scrollToSection(heading.current[index]);
    // }, 600);
  };

  useEffect(() => {
    const isActiveItem = currentActiveItem == index;

    if (accordionRef.current) {
      animateBody({
        height: (isActiveItem ? accordionRef.current.offsetHeight : 0) + "px",
      });
    }

    animateIcon({
      transform: `rotate(${isActiveItem ? 135 : 0}deg)`,
    });
  }, [currentActiveItem]);

  return (
    <div
      className={classNames("accordion__section", {
        current: currentActiveItem === index,
      })}
      ref={(ref: any) => (headingRef.current[index] = ref)}
      key={index}
    >
      <div className="accordion__head">
        <div className="accordion__head-title">{item.Title}</div>

        <div className="accordion__head-subtitle">{item.Subtitle}</div>

        <div className="accordion__head-action" onClick={handleClick}>
          <animated.i className="accordion__head-icon" style={iconStyles}>
            {/* <IconAccordionArrow /> */}
          </animated.i>
        </div>
      </div>

      <animated.div className="accordion__animate" style={{ ...bodyStyles }}>
        <div className="accordion__body" ref={accordionRef}>
          <Markdown content={item.Content} />

          {item.ButtonText && item.ButtonLink && (
            <Link
              href={item.ButtonLink}
              OpenInNewTab={item.OpenInNewTab}
              RelNoFollow={item.RelNoFollow}
              className="btn btn--fill"
            >
              {item.ButtonText}
            </Link>
          )}
        </div>
      </animated.div>
    </div>
  );
};

export default AccordionSection;

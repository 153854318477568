import ProjectsItem from "./projects-item";

interface ProjectsProp {
  data: any;
}

const Projects: React.FC<ProjectsProp> = ({ data }) => (
  <div className="projects-list">
    <div className="container">
      <div className="projects-list__inner">
        {data.Project.map((item: any, index: number) => (
          <ProjectsItem data={item} index={index} key={index} />
        ))}
      </div>
    </div>
  </div>
);

export default Projects;

import Markdown from "components/markdown/markdown";

interface SizeProp {
  data: {
    Title?: string;
    Content?: string;
    SizeBox?: SizeBoxProp[];
  };
}

interface SizeBoxProp {
  id: number;
  Title?: string;
  Content?: string;
}

const Size: React.FC<SizeProp> = ({ data }) => {
  return (
    <div className="size">
      <div className="container">
        <div className="size__inner">
          {data.Title && <h3>{data.Title}</h3>}
          <div className="size__content">
            {data.Content && <Markdown content={data.Content} />}
          </div>
          <div className="size__row">
            {data.SizeBox &&
              data.SizeBox.map((box: SizeBoxProp) => (
                <div className="size__box" key={box.id}>
                  {box.Title && (
                    <div className="size__box-title">{box.Title}</div>
                  )}
                  {box.Content && <Markdown content={box.Content} />}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Size;

import Markdown from "components/markdown/markdown";

interface SliderElementTestimonialProp {
  item: {
    Author: string;
    Position?: string;
    Content: string;
  };
}

const SliderElementTestimonial: React.FC<SliderElementTestimonialProp> = ({
  item,
}) => {
  return (
    <div className="slider-element__slide slider-element__slide--testimonial">
      <div className="testimonial">
        <div className="testimonial__body">
          {item.Content && (
            <div className="testimonial__content">
              <Markdown content={item.Content} />
            </div>
          )}

          <div className="testimonial__meta">
            {item.Author && (
              <div className="testimonial__meta-author">{item.Author}</div>
            )}

            {item.Position && (
              <div className="testimonial__meta-position">{item.Position}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SliderElementTestimonial;

/**
 * External dependencies
 */
import CountUp from "react-countup";
import { InView } from "react-intersection-observer";

interface ColProp {
  Sign: string;
  Value: number;
  Symbol: string;
  Text: string;
}

interface NumbersProps {
  data: {
    Columns: ColProp[];
  };
}

const Numbers: React.FC<NumbersProps> = ({ data }) => {
  const decimalCount = (val: number) => {
    return val.toString().includes(".")
      ? val.toString().split(".")[1].length
      : 0;
  };
  return (
    <div className="section-numbers">
      <div className="container">
        <div className="section__inner">
          <div className="section__cols section__cols-numbers">
            {data.Columns?.map((item: ColProp, index: number) => (
              <div className="section__col" key={index}>
                <div className="number">
                  {item.Sign && <div className="number__sign">{item.Sign}</div>}

                  {item.Value && (
                    <InView>
                      {({ inView, ref }) => (
                        <div className="number__value" ref={ref}>
                          {inView ? (
                            <CountUp
                              start={0}
                              duration={1}
                              end={item.Value}
                              decimals={decimalCount(item.Value)}
                            />
                          ) : (
                            0
                          )}
                        </div>
                      )}
                    </InView>
                  )}

                  {item.Symbol && (
                    <div className="number__symbol">{item.Symbol}</div>
                  )}
                </div>

                {item.Text && <div className="number-text">{item.Text}</div>}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Numbers;

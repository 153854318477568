/**
 * External dependencies.
 */
import Image from "next/image";

/**
 * Internal dependencies.
 */
import { getStrapiMedia } from "utils";
import { MediaProp } from "types";
import Markdown from "components/markdown/markdown";

interface GridBoxesProp {
  boxes: {
    Title?: string;
    Content?: string;
    Image?: MediaProp;
  }[];
}

const GridBoxes: React.FC<GridBoxesProp> = ({ boxes }) => (
  <div className="grid-boxes">
    <div className="container">
      <div className="grid-boxes__inner">
        {boxes.map((item: any, index) => (
          <div className="grid-box" key={`grid-box-${index}`}>
            {item.Media && item.Media.data && (
              <Image
                alt=""
                src={getStrapiMedia(item.Media)}
                width={item.Media.data.attributes.width}
                height={item.Media.data.attributes.height}
              />
            )}

            <div className="grid-box__content">
              <div className="grid-box__title">{item.Title}</div>
              <Markdown content={item.Content} />
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default GridBoxes;

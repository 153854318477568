/**
 * External dependencies
 */
import classNames from "classnames";

/**
 * Internal dependencies
 */
import IntroContent from "./intro-content";
import IntroImage from "./intro-image";

interface IntroProp {
  children: any;
  className?: string;
  noMargin?: boolean;
  Smaller?: boolean;
}

const Intro: React.FC<IntroProp> & {
  Content: any;
  Image: any;
} = ({ children, className, noMargin, Smaller }) => (
  <div
    className={classNames("intro", className, {
      "no-margin": noMargin,
      "intro--smaller": Smaller,
    })}
  >
    <div className={classNames("container", { "container--small": Smaller })}>
      <div className={classNames("intro__inner", className)}>{children}</div>
    </div>
  </div>
);

Intro.Content = IntroContent;
Intro.Image = IntroImage;

export default Intro;

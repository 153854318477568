/**
 * Internal dependencies
 */
import Markdown from "components/markdown/markdown";

interface TextProp {
  Title: string;
  List: string;
}

interface DescriptionProp {
  data: {
    Content: string;
    Text: TextProp[];
  };
}

const Description: React.FC<DescriptionProp> = ({ data }) => {
  const handleList = (entry: string) => {
    const listArr = entry.split("\n");
    return listArr.map((item: string, index: number) => (
      <p key={index}>{item}</p>
    ));
  };

  return (
    <div className="description">
      <div className="container">
        <div className="description__inner">
          <div className="description__side">
            {data.Text.map((text, index) => (
              <div key={index} className="description__text">
                <div className="description__text-title">{text.Title}</div>
                {handleList(text.List)}
              </div>
            ))}
          </div>
          <div className="description__content">
            <Markdown content={data.Content} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Description;

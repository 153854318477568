/**
 * External dependencies.
 */
import Image from "next/image";

/**
 * Internal dependencies
 */
import Link from "components/link/link";
import { getStrapiMedia } from "utils";
import { MediaProp } from "types";

interface MembersProps {
  Photo?: MediaProp;
  Name?: string;
  Position?: string;
}

interface ButtonProps {
  Text: string;
  Link?: string;
  NewTab?: boolean;
}

interface TeamProps {
  data: {
    Title?: string;
    Members: MembersProps[];
    Button: ButtonProps;
  };
}

const Team: React.FC<TeamProps> = ({ data }) => {
  return (
    <div className="section-team">
      <div className="container">
        <div className="section__inner">
          {data.Title && <h3>{data.Title}</h3>}

          <div className="team">
            {data.Members.map((item: MembersProps, index: number) => (
              <div key={index} className="team__member">
                {item.Photo && item.Photo.data && (
                  <div className="team__member-photo">
                    <Image
                      alt=""
                      src={getStrapiMedia(item.Photo)}
                      width={item.Photo.data.attributes.width}
                      height={item.Photo.data.attributes.height}
                    />
                  </div>
                )}

                {item.Name && (
                  <div className="team__member-name">{item.Name}</div>
                )}

                {item.Position && (
                  <div className="team__member-position">{item.Position}</div>
                )}
              </div>
            ))}
          </div>

          {data?.Button?.Text && data?.Button?.Link && (
            <div className="section__actions">
              <Link
                href={data.Button.Link}
                OpenInNewTab={data.Button.NewTab}
                className="btn btn--fill"
              >
                {data.Button.Text}
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Team;

import classNames from "classnames";
import Image from "next/image";

interface SupportProp {
  children: any;
  link: JSX.Element;
  className?: string;
  image?: {
    src: string;
    width: number;
    height: number;
  };
}

const Support: React.FC<SupportProp> = ({
  children,
  image,
  link,
  className,
}) => (
  <div className={classNames("support", className)}>
    <div className="container">
      <div
        className="support__inner"
        // data-aos="zoom-out"
      >
        <div className="support__inner-left">
          {children}
          <div className="support__inner-bottom-link">{link}</div>
        </div>
        <div className="support__inner-right">
          <div className="support-image">
            {image && (
              <Image src={image} alt="Support image" width={731} height={594} />
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Support;

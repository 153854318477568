import Link from "components/link/link";
import Image from "next/image";
import { MediaProp } from "types";
import { getStrapiMedia } from "utils";

interface GalleryItemProp {
  data: {
    Image: MediaProp;
    Link?: string;
    OpenInNewTab: boolean;
    RelNoFollow: boolean;
  };
}

const GalleryItem: React.FC<GalleryItemProp> = ({ data }) => {
  const item = (
    <div className="gallery-item">
      <Image alt="" src={getStrapiMedia(data.Image)} layout="fill" />
    </div>
  );

  if (!data.Link) {
    return item;
  }

  return (
    <Link
      href={data.Link}
      OpenInNewTab={data.OpenInNewTab}
      RelNoFollow={data.RelNoFollow}
    >
      {item}
    </Link>
  );
};

export default GalleryItem;

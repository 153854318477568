/**
 * External dependencies
 */
import { useState, Fragment, useRef } from "react";
import classNames from "classnames";
import { LazyMotion, m } from "framer-motion";

/**
 * Internal dependencies
 */
import Markdown from "components/markdown/markdown";
import IconTabArrow from "../../assets/images/svg/icon-tab-arrow.svg";
import { scrollToSection } from "utils";
const loadFeatures = () =>
  import("../../motion-features").then(res => res.default)

interface TabProp {
  id: Number;
  Title: String;
  Content: String;
}

interface TabsProps {
  data: {
    Tab: TabProp[];
  };
}

const cardVariant = {
  active: {
    opacity: 1,
    display: "block",
    transition: {
      duration: 0.5,
    },
  },
  inactive: {
    opacity: 0,
    display: "none",
    transition: {
      duration: 0.5,
    },
  },
};

const Tabs: React.FC<TabsProps> = ({ data }) => {
  const [activeTab, setActiveTab] = useState(0);
  const content: any = useRef([]);

  const handleClick = (index: number) => {
    setActiveTab(index);
    setTimeout(() => {
      scrollToSection(content.current[index]);
    }, 400);
  };

  return (
    <div className="tabs">
      <div className="container container--small">
        <div className="tabs__inner">
          <div className="tabs__side">
            <ul>
              {data.Tab?.map((item: TabProp, index: number) => (
                <li key={index}>
                  <h2>
                    <button
                      onClick={() => setActiveTab(index)}
                      className={classNames("tab-button", {
                        "is-active": activeTab === index,
                      })}
                    >
                      {item.Title}
                      <span className="tab-button__icon">
                        <IconTabArrow />
                      </span>
                    </button>
                  </h2>
                </li>
              ))}
            </ul>
          </div>
          <LazyMotion features={loadFeatures}>
            <m.div role="tabpanel" className="tabs__content">
              {data.Tab?.map((item: TabProp, index: number) => (
                <Fragment key={index}>
                  <div
                    className="tabs__content-title"
                    ref={(ref) => (content.current[index] = ref)}
                  >
                    <button
                      onClick={() => handleClick(index)}
                      className={classNames("tab-button", {
                        "is-active": activeTab === index,
                      })}
                    >
                      {item.Title}
                      <span className="tab-button__icon">
                        <IconTabArrow />
                      </span>
                    </button>
                  </div>
                  <LazyMotion features={loadFeatures}>
                    <m.div
                      className="tab-content"
                      variants={cardVariant}
                      animate={activeTab === index ? "active" : "inactive"}
                    >
                      <div className="tab-content__inner">
                        <Markdown content={item.Content} />
                      </div>
                    </m.div>
                  </LazyMotion>
                </Fragment>
              ))}
            </m.div>
          </LazyMotion>
        </div>
      </div>
    </div>
  );
};

export default Tabs;

import { createElement } from "react";

interface HeadingRowProp {
  data: {
    Title: string;
    MaxWidth: number;
    Type: string;
  };
}

const HeadingRow: React.FC<HeadingRowProp> = ({ data }) => {
  const title = createElement(data.Type, {}, data.Title);
  return (
    <div className="heading-row">
      <div className="container">
        <div
          className="heading-row__inner"
          style={{ maxWidth: data.MaxWidth + "%" }}
        >
          {title}
        </div>
      </div>
    </div>
  );
};

export default HeadingRow;

/**
 * External dependencies.
 */
import React from "react";
import classNames from "classnames";
import Image from "next/image";

/**
 * Internal dependencies.
 */
import { getStrapiMedia, getStrapiMediaArr } from "utils";
import Intro from "components/intro/intro";
import { MediaProp } from "types";
import Markdown from "components/markdown/markdown";
import LinkWithOptions from "components/link/link-with-options";

interface HomeIntroProp {
  data: {
    hide?: boolean;
    Title?: string;
    Content?: string;
    Link1: {
      OpenInNewTab: boolean;
      RelNoFollow: boolean;
      Text: string;
      URL: string;
    };
    Link2: {
      OpenInNewTab: boolean;
      RelNoFollow: boolean;
      Text: string;
      URL: string;
    };
    Logos: {
      data?: ImageProp[];
    };
    Image?: MediaProp;
    Image2?: MediaProp;
    NoMargin?: boolean;
    Smaller?: boolean;
  };
}

interface ImageProp {
  id: number;
  attributes: {
    data: {
      attributes: {
        url: string;
      };
    };
  };
}

const HomeIntro: React.FC<HomeIntroProp> = ({ data }) => (
  <Intro
    Smaller={data.Smaller}
    noMargin={data.NoMargin}
    className={classNames({
      "no-images": !data.Image?.data && !data.Image2?.data,
    })}
  >
    <Intro.Content>
      {data.Title && <h1 className="h3">{data.Title}</h1>}
      {data.Content && <Markdown content={data.Content} />}
      {(data.Link1 || data.Link2) && (
        <div className="cta-row">
          {data.Link1 && (
            <LinkWithOptions data={data.Link1} className="btn btn--fill" />
          )}
          {data.Link2 && (
            <LinkWithOptions data={data.Link2} className="btn btn--white" />
          )}
        </div>
      )}
      {data.Logos.data && (
        <div className="logos-row">
          {data.Logos.data.map((item: ImageProp) => (
            <Image
              alt=""
              src={getStrapiMediaArr(item)}
              key={item.id}
              width={152}
              height={25}
              style={{ height: "1.625rem", width: "auto" }}
            />
          ))}
        </div>
      )}
    </Intro.Content>
    {(data.Image?.data || data.Image2?.data) && (
      <Intro.Image
        className={classNames({
          "two-images":
            data.Image && data.Image.data && data.Image2 && data.Image2.data,
        })}
      >
        {data.Image && data.Image.data && (
          <div className="image-1">
            <div className="image-1__inner">
              <Image
                src={getStrapiMedia(data.Image)}
                alt="Intro image"
                width={data.Image.data.attributes.width}
                height={data.Image.data.attributes.height}
                priority
              />
            </div>
            {data.Image2 && data.Image2.data && (
              <div className="image-2">
                <div className="image-2__inner">
                  <Image
                    src={getStrapiMedia(data.Image2)}
                    alt="Intro image"
                    width={data.Image2.data.attributes.width}
                    height={data.Image2.data.attributes.height}
                    priority
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </Intro.Image>
    )}
  </Intro>
);

export default HomeIntro;

import classNames from "classnames";

interface IntroImageProp {
  children: JSX.Element;
  className?: string;
}

const IntroImage: React.FC<IntroImageProp> = ({ children, className }) => (
  <div className={classNames("intro__image", className)}>{children}</div>
);

export default IntroImage;

/**
 * External dependencies.
 */
import Image from "next/image";

/**
 * Internal dependencies.
 */
import Link from "components/link/link";
import { getStrapiMedia } from "utils";
import { MediaProp } from "types";

interface StoreImagesProp {
  title?: string;
  data: {
    Link?: string;
    id?: number;
    Image?: MediaProp;
    OpenInNewTab?: boolean;
    RelNoFollow?: boolean;
  }[];
}

const StoreImages: React.FC<StoreImagesProp> = ({ data, title }) => (
  <div className="store-images">
    <div className="container">
      {title && <h4>{title}</h4>}
      {data.length > 0 && (
        <div className="store-images__inner">
          {data?.map((item, index) => {
            if (item.Link && item.Image && item.Image.data) {
              return (
                <Link
                  href={item.Link}
                  key={index}
                  className="store-image"
                  OpenInNewTab={item.OpenInNewTab}
                  RelNoFollow={item.RelNoFollow}
                >
                  <Image
                    alt=""
                    src={getStrapiMedia(item.Image)}
                    width={item.Image.data.attributes.width}
                    height={item.Image.data.attributes.height}
                  />
                </Link>
              );
            } else if (!item.Link && item.Image && item.Image.data) {
              return (
                <Image
                  key={index}
                  alt=""
                  src={getStrapiMedia(item.Image)}
                  width={item.Image.data.attributes.width}
                  height={item.Image.data.attributes.height}
                />
              );
            }
          })}
        </div>
      )}
    </div>
  </div>
);

export default StoreImages;

/**
 * External dependencies
 */
import useEmblaCarousel from "embla-carousel-react";

/**
 * Internal dependencies
 */
import { MediaProp } from "types";
import GalleryItem from "./gallery-item";

interface SlideProp {
  Image: MediaProp;
  Link?: string;
  OpenInNewTab: boolean;
  RelNoFollow: boolean;
}

interface GalleryProp {
  data: {
    Slide: SlideProp[];
  };
}

const Gallery: React.FC<GalleryProp> = ({ data }) => {
  const [viewportRef] = useEmblaCarousel({
    dragFree: true,
    align: "start",
    containScroll: "trimSnaps",
  });
  return (
    <div className={"gallery"}>
      <div className="container">
        <div className="embla__viewport" ref={viewportRef}>
          <div className="embla__container">
            {data.Slide.map((item, index) => (
              <GalleryItem key={index} data={item} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;

import Head from "next/head";
import { MediaProp } from "types";
import { getStrapiMedia } from "utils";

interface SEOType {
  data: {
    Title?: string;
    MetaTitle?: string;
    MetaDescription?: string;
    MetaKeywords?: string;
    og_image?: MediaProp;
    og_url?: string;
    og_type?: string;
    og_title?: string;
    og_description?: string;
    twitter_title?: string;
    twitter_description?: string;
    twitter_image?: MediaProp;
    twitter_card?: string;
    IndexNoFollow?: string;
    Canonical?: string;
    RobotTXT?: string;
    HrefLang?: {
      hreflang?: string;
      href?: string;
    }[];
  };
}

const SEO: React.FC<SEOType> = ({ data }) => {
  if (!data) {
    return <></>;
  }

  const {
    Title,
    MetaTitle,
    MetaDescription,
    MetaKeywords,
    og_image,
    og_url,
    og_type,
    og_title,
    og_description,
    twitter_title,
    twitter_description,
    twitter_image,
    twitter_card,
    IndexNoFollow,
    Canonical,
    RobotTXT,
  } = data;

  return (
    <Head>
      {Title && <title>{Title}</title>}
      {MetaTitle && <meta name="title" content={MetaTitle} />}
      {MetaDescription && <meta name="description" content={MetaDescription} />}
      {MetaKeywords && <meta name="keywords" content={MetaKeywords} />}
      {og_image && (
        <meta property="og:image" content={getStrapiMedia(og_image)} />
      )}
      {og_url && <meta property="og:url" content={og_url} />}
      {og_type && <meta property="og:type" content={og_type} />}
      {og_title && <meta property="og:title" content={og_title} />}
      {og_description && (
        <meta property="og:description" content={og_description} />
      )}
      {twitter_title && <meta name="twitter:title" content={twitter_title} />}
      {twitter_description && (
        <meta name="twitter:description" content={twitter_description} />
      )}
      {twitter_image && (
        <meta name="twitter:image" content={getStrapiMedia(twitter_image)} />
      )}
      {twitter_card && twitter_card !== "none" && (
        <meta name="twitter:card" content={twitter_card} />
      )}
      {IndexNoFollow && IndexNoFollow !== "none" && (
        <meta name="robots" content={IndexNoFollow} />
      )}
      {Canonical && <link rel="canonical" href={Canonical} />}
      {RobotTXT && <meta name="robots" content={RobotTXT} />}
    </Head>
  );
};

export default SEO;

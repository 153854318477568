/**
 * External dependencies
 */
import { useState, useRef } from "react";

/**
 * Internal dependencies
 */
import Markdown from "components/markdown/markdown";
import AccordionSection from "blocks/accordion/accordion-section";
import { scrollToSectionAccordion } from "utils";

interface AccordionProp {
  Title: String;
  Subtitle: String;
  Content: String;
  ButtonText: String;
  ButtonLink: String;
}

interface AccordionsProps {
  data: {
    Title?: string;
    Content?: string;
    Accordion: AccordionProp[];
  };
}

const Accordions: React.FC<AccordionsProps> = ({ data }) => {
  const [activeItem, setActiveItem] = useState<number | null>(0);

  const heading: any = useRef([]);

  function dispatchClick(activeIndex: number) {
    setActiveItem(null);
    setTimeout(() => {
      scrollToSectionAccordion(heading.current[activeIndex]);
    }, 300);
    setTimeout(() => {
      setActiveItem(activeIndex);
    }, 400);
  }

  return (
    <div className="section-accordion">
      <div className="container">
        <div className="section__inner">
          <div className="section__cols section__cols-accordion">
            <div className="section__col">
              {data.Title && <h2>{data.Title}</h2>}

              {data.Content && <Markdown content={data.Content} />}
            </div>

            <div className="section__col">
              <div className="accordion">
                {data.Accordion.map((item: AccordionProp, index: number) => (
                  <AccordionSection
                    key={index}
                    item={item}
                    index={index}
                    dispatchClick={() => dispatchClick(index)}
                    currentActiveItem={activeItem}
                    headingRef={heading}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accordions;

/**
 * External dependencies
 */
import { useEffect, useRef, useState } from "react";
import TextTransition, { presets } from "react-text-transition";

import classNames from "classnames";

/**
 * Internal dependencies
 */
import { useMediaQuery } from "utils";

interface IntroSlideTextTransitionProp {
  data: {
    Title?: string;
    id: number;
  }[];
}

const IntroSlideTextTransition: React.FC<IntroSlideTextTransitionProp> = ({
  data,
}) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => setIndex((index) => index + 1), 3000);
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <span
      className={classNames(
        "intro-slide-text-transition",
        (data[index % data.length].Title?.length as number) > 16
          ? "intro-slide-text-transition-block"
          : "intro-slide-text-transition-auto"
      )}
    >
      <TextTransition springConfig={presets.default}>
        {data[index % data.length].Title}
      </TextTransition>
    </span>
  );
};

export default IntroSlideTextTransition;

import Image from "next/image";
import { getStrapiMediaArr } from "utils";
import IntroSlideTextTransitionSlider from "./intro-slide-text-transition";
import IntroSlideTextSlider from "./intro-slide-text-slider";

interface TitleProp {
  Title?: string;
  id: number;
}

interface ImageProp {
  id: number;
  attributes: {
    data: {
      attributes: {
        url: string;
      };
    };
  };
}

interface IntroSlideTextProp {
  data: {
    TextBefore?: string;
    TextAfter?: string;
    Logos?: {
      data: ImageProp[];
    };
    Text?: TitleProp[];
  };
}

const IntroSlideText: React.FC<IntroSlideTextProp> = ({ data }) => {
  return (
    <div className="intro-slide-text">
      <div className="container container--small">
        <div className="intro-slide-text__inner">
          <h1 className="intro-slide-text__head">
            <span className="intro-slide-text__title">{data.TextBefore}</span>
            {/* {data.Text && <IntroSlideTextSlider data={data.Text} />} */}
            {data.Text && <IntroSlideTextTransitionSlider data={data.Text} />}
            <span style={{ display: "block" }}>
              <span>{data.TextAfter}</span>
            </span>
          </h1>
          {data.Logos?.data && (
            <div className="logos-row">
              {data.Logos.data?.map((item: ImageProp) => (
                <Image
                  alt=""
                  src={getStrapiMediaArr(item)}
                  key={item.id}
                  width={152}
                  height={25}
                  style={{ height: "1.625rem", width: "auto" }}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default IntroSlideText;

/**
 * External dependencies.
 */
import classNames from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";

interface BtnLinkProp {
  href: string;
  children: any;
  className?: string;
  title?: any;
  color?: string;
  OpenInNewTab?: boolean;
  RelNoFollow?: boolean;
}

const BtnLink: React.FC<BtnLinkProp> = ({
  children,
  href,
  className,
  title,
  OpenInNewTab,
  RelNoFollow,
  color = "#301DCD",
}) => {
  const { locale } = useRouter();

  return (
    <Link
      href={`/${locale}${href}`}
      locale={false}
      className={classNames("btn-link", className)}
      title={title}
      style={{ color }}
      target={OpenInNewTab ? "_blank" : "_self"}
      rel={RelNoFollow ? "nofollow" : ""}
    >
      {children}
      <svg
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="btn-link__arrow"
      >
        <path
          d="M12.0328 3.54175L11.1573 4.41565L14.622 7.88029L-1.89644e-07 7.88029L-2.43828e-07 9.11987L14.622 9.11987L11.1511 12.5783L12.0328 13.4584L17 8.50008L12.0328 3.54175Z"
          fill={color}
        />
      </svg>
    </Link>
  );
};

export default BtnLink;

import ImageTab from "blocks/image-tab/image-tab";
import { MediaProp } from "types";

interface HomeImageTabProp {
  data: {
    Title: string;
    DesktopImage?: MediaProp;
    MobileImage?: MediaProp;
  }[];
  title: string;
  link: {
    text: string;
    url: string;
  };
}

const HomeImageTab: React.FC<HomeImageTabProp> = ({ data, title, link }) => {
  const tabs = data.reduce((acc: any, val: any) => {
    acc.push({
      title: val.Title,
      image: val.DesktopImage,
      mobile: val.MobileImage,
    });
    return acc;
  }, []);

  return (
    link && (
      <ImageTab tabs={tabs} link={link}>
        <h2>{title}</h2>
      </ImageTab>
    )
  );
};

export default HomeImageTab;

import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

interface MarkdownProp {
  content: any;
}

const Markdown: React.FC<MarkdownProp> = ({ content }) => (
  <ReactMarkdown
    rehypePlugins={[rehypeRaw]}
    transformImageUri={(uri) =>
      uri.startsWith("http")
        ? uri
        : `${process.env.NEXT_PUBLIC_STRAPI_API_URL}${uri}`
    }
  >
    {content}
  </ReactMarkdown>
);

export default Markdown;

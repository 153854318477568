/**
 * External dependencies.
 */
import classNames from "classnames";

/**
 * Internal dependencies.
 */
import Markdown from "components/markdown/markdown";

interface FeatureBoxesProp {
  boxes: FeatureBoxesItemProp;
}

interface FeatureBoxesItemProp {
  Title?: string;
  Content?: string;
  FeatureBoxes?: FeatureBoxesListProp[];
  Divider?: boolean;
}

interface FeatureBoxesListProp {
  id: number;
  Title: string;
  Content: string;
}

const FeatureBoxes: React.FC<FeatureBoxesProp> = ({ boxes }) => {
  const { Title, Content, FeatureBoxes, Divider } = boxes;

  return (
    <div className="feature-boxes">
      <div
        className={classNames("feature-box", {
          "is-last": !Divider,
        })}
      >
        <h6>{Title}</h6>
        {Content && (
          <div className="feature-box__desc">
            <Markdown content={Content} />
          </div>
        )}
        <ul>
          {FeatureBoxes &&
            FeatureBoxes.map((list: FeatureBoxesListProp) => (
              <li key={`box-${list.id}`}>
                <div className="box-title">{list.Title}</div>
                <div className="box-content">{list.Content}</div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default FeatureBoxes;

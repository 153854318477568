// utils

import { getStrapiMedia } from "utils";

// types

import { MediaProp } from "types";

// components

import Image from "next/image";
import Link from "components/link/link";

interface BadgeProp {
  Title: string;
}

interface SliderElementProjectProp {
  project: {
    project: {
      data: {
        attributes: {
          slug: string;
          Subtitle: string;
          Title: string;
          Image: MediaProp;
          Link: string;
          OpenInNewTab: boolean;
          Badge: BadgeProp[];
        };
      };
    };
  };
}

const SliderElementProject: React.FC<SliderElementProjectProp> = ({
  project: { project: projectData },
}) => {
  const { attributes: project } = projectData?.data ?? { attributes: {} };
  return (
    <div className="slider-element__slide slider-element__slide--project">
      <Link
        href={`/client/${project.slug}`}
        OpenInNewTab={project.OpenInNewTab}
        className="slider-element__slide-inner"
      >
        {project.Image && (
          <Image
            alt=""
            src={getStrapiMedia(project.Image)}
            layout="fill"
            priority={true}
          />
        )}
        <div className="slider-element__slide-text">
          <div className="h4">{project.Title}</div>
          <p>{project.Subtitle}</p>
        </div>
        {project.Badge && (
          <div className="slider-element__badge">
            {project.Badge.map((item: BadgeProp, index: number) => (
              <div className="slider-element__badge-inner" key={index}>
                <span>{item.Title}</span>
              </div>
            ))}
          </div>
        )}
      </Link>
    </div>
  );
};

export default SliderElementProject;

import Image from "next/image";
import { MediaProp } from "types";
import { getStrapiMedia } from "utils";

interface BannerProps {
  data: {
    Title: string;
    Subtitle: string;
    Image: MediaProp;
  };
}

const Banner: React.FC<BannerProps> = ({ data }) => {
  return (
    <div className="banner">
      <Image
        alt=""
        src={getStrapiMedia(data.Image)}
        priority
        layout="fill"
        objectFit="cover"
      />
      <div className="banner__text">
        <div className="container">
          <div className="banner__text-inner">
            <h6>{data.Subtitle}</h6>
            <h1 className="h4">{data.Title}</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;

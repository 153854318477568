/**
 * External dependencies.
 */
import classNames from "classnames";
import Image from "next/image";
import { useRef, useState } from "react";
import { LazyMotion, m } from "framer-motion";

/**
 * Internal dependencies.
 */
import { MediaProp } from "types";
import BtnLink from "components/btn-link/btn-link";
import { getStrapiMedia } from "utils";

const loadFeatures = () =>
  import("../../motion-features").then((res) => res.default);

interface ImageTabProp {
  children: JSX.Element;
  tabs: {
    title: string;
    image: MediaProp;
    mobile: MediaProp;
    alt: string;
  }[];
  link: {
    text: string;
    url: string;
  };
}

const cardVariant = {
  active: {
    opacity: 1,
    display: "block",
    transition: {
      duration: 0.5,
    },
  },
  inactive: {
    opacity: 0,
    display: "none",
    transition: {
      duration: 0.5,
    },
  },
};

const ImageTab: React.FC<ImageTabProp> = ({ children, tabs, link }) => {
  const rowRef: any = useRef();
  const [active, setActive] = useState(0);

  const handleClick: (index: number) => void = (index) => setActive(index);

  return (
    <div className="image-tab">
      <div className="container container--small">
        <div
          className="image-tab__head"
          // data-aos="fade-up"
        >
          {children}
        </div>
        <div
          className="image-tab__row"
          // data-aos="fade-up"
          ref={rowRef}
        >
          <div className="image-tab__row-left">
            <BtnLink href={link.url} title={link.text}>
              {link.text}
            </BtnLink>
          </div>
          <div className="image-tab__row-right">
            {tabs.map((item, index) => (
              <button
                key={index}
                onClick={() => handleClick(index)}
                className={classNames({ "is-active": index === active })}
              >
                {item.title}
              </button>
            ))}
          </div>
        </div>
        <LazyMotion features={loadFeatures}>
          <m.div role="tabpanel">
            <div
              className="image-tab__row-image"
              // data-aos="fade-up"
            >
              {tabs.map((item, index) => (
                <LazyMotion features={loadFeatures} key={index}>
                  <m.div
                    className={classNames("slide", {
                      "is-active": index === active,
                    })}
                    variants={cardVariant}
                    animate={active === index ? "active" : "inactive"}
                  >
                    <div className="slide-image">
                      <div className="slide-image__desktop">
                        {item.image?.data && (
                          <Image
                            src={getStrapiMedia(item.image)}
                            alt={item.alt}
                            width={item.image.data.attributes.width}
                            height={item.image.data.attributes.height}
                          />
                        )}
                      </div>
                      {item.mobile?.data && (
                        <div className="slide-image__mobile">
                          <div className="slide-image__mobile-inner">
                            <Image
                              src={getStrapiMedia(item.mobile)}
                              alt={item.alt}
                              width={item.mobile.data.attributes.width}
                              height={item.mobile.data.attributes.height}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </m.div>
                </LazyMotion>
              ))}
            </div>
          </m.div>
        </LazyMotion>
      </div>
    </div>
  );
};

export default ImageTab;

/**
 * External dependencies
 */
import { Fragment } from "react";
import dynamic from "next/dynamic";
import classNames from "classnames";

/**
 * Internal dependencies
 */
import { getStrapiMedia, getStrapiMediaArr } from "utils";
import TextImage from "components/text-image/text-image";
import GridBoxes from "components/grid-boxes/grid-boxes";
import Features from "components/features/features";
import HomeIntro from "blocks/home-intro/home-intro";
import TextBlock from "components/text-block/text-block";
const Video = dynamic(() => import("components/video/video"), {
  ssr: false,
});
import Size from "components/size/size";
import Alternating from "blocks/alternating/alternating";
import HomeImageTabBlock from "blocks/home-image-tab/home-image-tab";
import Logos from "components/logos/logos";
import Image from "next/image";
import Markdown from "components/markdown/markdown";
import Stack from "components/stack/stack";
import Support from "components/support/support";
import BtnLinkWithOptions from "components/btn-link/btn-link-with-options";
import Tabs from "blocks/tabs/tabs";
import SliderElement from "blocks/slider-element/slider-element";
import Projects from "components/projects/projects";
import Banner from "blocks/banner/banner";
import Description from "blocks/description/description";
import Gallery from "blocks/gallery/gallery";
import Accordion from "blocks/accordion/accordion";
import Team from "blocks/team/team";
import Testimonial from "blocks/testimonial/testimonial";
import Numbers from "blocks/numbers/numbers";
import Images from "blocks/images/images";
import HeadingRow from "blocks/heading-row/heading-row";
import IntroSlideText from "blocks/intro-slide-text/intro-slide-text";
import PromiseDynamic from "components/promise/promise";
import StoreImages from "components/store-images/store-images";

const DynamicSection = ({ data, removeClass }: any) => {
  const handleDynamicComponent = (item: any, index: number) => {
    if (item.hide) {
      return null;
    }

    switch (item["__component"]) {
      case "sections.tabs":
        return (
          <HomeImageTabBlock
            key={index}
            data={item.ImageTabs}
            title={item.ImageTabTitle}
            link={{ text: item.ImageTabLinkText, url: item.ImageTabLinkURL }}
          />
        );
      case "sections.intro-slide-text":
        return <IntroSlideText key={index} data={item} />;
      case "sections.tabs-list":
        return <Tabs key={index} data={item} />;
      case "home-intro.home-intro":
        return <HomeIntro data={item} key={index} />;
      case "sections.stores":
        return <StoreImages key={index} title={item.title} data={item.store} />;
      case "sections.logos":
        return <Logos logos={item.LogosImages.data} item={item} key={index} />;
      case "pr.pr":
        const promiseData = item.PromiseBoxes?.map((item: any) => ({
          title: item.Title,
          icon: item.Icon?.data ? (
            <Image
              alt=""
              src={getStrapiMedia(item.Icon)}
              width={24}
              height={24}
            />
          ) : (
            ""
          ),
          message: <Markdown content={item.Content} />,
        }));
        return (
          <PromiseDynamic
            key={index}
            data={promiseData}
            subtitle={item.Subtitle}
            title={item.Title}
            link={item.Link}
          />
        );
      case "sections.stack":
        const stackData = item.StackPartners?.data?.map((item: any) => (
          <Image
            alt=""
            src={getStrapiMediaArr(item)}
            width={114}
            height={38}
            key={item.id}
          />
        ));
        return (
          <Stack logos={stackData} item={item} key={index}>
            <Markdown content={item.StackTitle} />
            <Markdown content={item.StackContent} />
          </Stack>
        );
      case "general.sliders":
        return <SliderElement data={item} key={index} />;
      case "support.support":
        return (
          <Support
            key={index}
            image={
              item.Image.data && {
                src: getStrapiMedia(item.Image),
                width: 763,
                height: 461,
              }
            }
            link={<BtnLinkWithOptions data={item.Link} children={undefined} />}
          >
            <h3>{item.Title}</h3>
            <Markdown content={item.Content} />
          </Support>
        );
      case "video.video":
        return <Video VideoBlock={item} key={index} />;
      case "size.size":
        return <Size data={item} key={index} />;
      case "text-block.text-block":
        return <TextBlock data={item} key={index} />;
      case "boxes-section.boxes-section":
        return <GridBoxes boxes={item.BoxItem} key={index} />;
      case "text-image.text-image":
        return <TextImage data={item} key={index} />;
      case "feature-row.feature-row":
        return <Features boxes={item} key={index} />;
      case "sections.projects":
        return <Projects data={item} key={index} />;
      case "sections.description":
        return <Description data={item} key={index} />;
      case "sections.gallery":
        return <Gallery data={item} key={index} />;
      case "sections.banner":
        return <Banner data={item} key={index} />;
      case "alternate.alternate":
        return <Alternating data={item} key={index} />;
      case "sections.accordion":
        return <Accordion data={item} key={index} />;
      case "sections.team":
        return <Team data={item} key={index} />;
      case "sections.testimonial":
        return <Testimonial data={item} key={index} />;
      case "sections.heading-row":
        return <HeadingRow data={item} key={index} />;
      case "sections.numbers":
        return <Numbers data={item} key={index} />;
      case "sections.images":
        return <Images data={item} key={index} />;
      default:
        return <Fragment key={index}></Fragment>;
    }
  };
  return (
    <div className={classNames({ "dynamic-section": !removeClass })}>
      {data?.map((item: any, index: number) =>
        handleDynamicComponent(item, index)
      )}
    </div>
  );
};

export default DynamicSection;

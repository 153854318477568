/**
 * External dependencies.
 */
import BtnLinkWithOptions from "components/btn-link/btn-link-with-options";

interface PromiseProp {
  subtitle?: string;
  title?: string;
  data?: any;
  link?: {
    URL: string;
    Text: string;
    OpenInNewTab: boolean;
    RelNoFollow: boolean;
  };
}

const Promise: React.FC<PromiseProp> = ({ subtitle, title, data, link }) => (
  <div className="promise">
    <div className="container container--small">
      <p className="promise__subtitle">{subtitle}</p>
      <div className="promise__head">
        <h3>{title}</h3>
        {link && (
          <BtnLinkWithOptions data={link} children={undefined} color="#fff" />
        )}
      </div>
      <div className="promise__content">
        {data?.map((item: any, index: number) => (
          <div
            className="promise__content-item"
            key={`promise-${index}`}
            // data-aos="zoom-in"
            // data-aos-delay={index * 100}
          >
            <div className="promise__content-title">
              <span className="promise-icon">{item.icon}</span>
              {item.title}
            </div>
            {item.message}
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default Promise;

/**
 * External dependencies.
 */
import React from "react";
import Image from "next/image";
import Link from "next/link";

/**
 * Internal dependencies
 */
import { articleDate, getStrapiMedia } from "utils";
import { ArticleAttrProps, ArticleTagProps } from "types/article";
import { useRouter } from "next/router";

interface ArticleContentDataProps {
  attributes: ArticleAttrProps;
}

interface ArticleContentProps {
  data: ArticleContentDataProps;
}

const ArticleContent: React.FC<ArticleContentProps> = ({ data }) => {
  const { locale } = useRouter();
  const {
    attributes: {
      Image: Img,
      Title,
      article_tags: tags,
      createdAt,
      slug,
      slug_overwrite,
    },
  } = data;
  const urlPath = `/${locale}/blog/${slug_overwrite || slug}`;

  return (
    <div className="article-box">
      {Img?.data?.attributes && (
        <div className="article__image">
          <Link href={urlPath} locale={false} passHref>
            <Image
              alt=""
              src={getStrapiMedia(Img)}
              layout="responsive"
              width={415}
              height={260}
              priority={true}
            />
          </Link>
        </div>
      )}

      <div className="article__meta">
        <div className="article__meta-created">{articleDate(createdAt)}</div>

        {tags?.data && tags.data.length !== 0 && (
          <div className="article__meta-tags">
            {tags.data?.map((tag: ArticleTagProps, index: number) => (
              <span key={index}>{tag.attributes.Name}</span>
            ))}
          </div>
        )}
      </div>

      {Title && (
        <div className="article__title">
          <Link href={urlPath} locale={false} passHref>
            {Title}
          </Link>
        </div>
      )}
    </div>
  );
};

export default ArticleContent;

interface StackProp {
  children: any;
  logos?: any;
  item?: any;
}

const Stack: React.FC<StackProp> = ({ children, logos, item }) => {
  if (item.hide) {
    return null;
  }

  return (
    <div className="stack">
      <div className="container container--small">
        <div
          className="stack__head"
          // data-aos="fade-up"
        >
          {children}
        </div>
        <div
          className="stack__logos"
          // data-aos="fade-up"
        >
          {logos &&
            logos.map((item: any, index: number) => (
              <div className="logo" key={index}>
                {item}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Stack;

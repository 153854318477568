/**
 * External dependencies.
 */
import React from "react";
import classNames from "classnames";

/**
 * Internal dependencies.
 */
import Link from "components/link/link";
import BtnLink from "components/btn-link/btn-link";
import Markdown from "components/markdown/markdown";

interface TextBlockProp {
  data: {
    Title?: string;
    Heading: string;
    Content?: string;
    LinkURL?: string;
    LinkText?: string;
    Align?: string;
    AlignMobile?: string;
    ButtonType?: string;
    ContainerWidth?: string;
    OpenInNewTab: boolean;
    RelNoFollow: boolean;
    hide?: boolean;
    IncreaseSpace?: boolean;
  };
}

const TextBlock: React.FC<TextBlockProp> = ({ data }) => {
  const Heading: any = () =>
    React.createElement(data.Heading, null, data.Title);

  if (data.hide) {
    return null;
  }

  const LinkEle = () => {
    if (!data.LinkURL || !data.LinkText) {
      return null;
    }

    if (data.ButtonType === "Link") {
      return <BtnLink href={data.LinkURL}>{data.LinkText}</BtnLink>;
    } else {
      return (
        <Link
          href={data.LinkURL}
          OpenInNewTab={data.OpenInNewTab}
          RelNoFollow={data.RelNoFollow}
          className="btn btn--fill"
        >
          {data.LinkText}
        </Link>
      );
    }
  };

  return (
    <div
      className={classNames("text-block", `text-block--${data.Align}`, {
        [`text-block--mobile-${data.AlignMobile}`]: data.AlignMobile,
        "text-block--increase-space": data.IncreaseSpace,
      })}
    >
      <div className="container container--small">
        <div
          className={classNames(
            "text-block__inner",
            `text-block__inner--${data.ContainerWidth}`
          )}
        >
          {data.Title && <Heading />}
          {data.Content && <Markdown content={data.Content} />}
          {LinkEle()}
        </div>
      </div>
    </div>
  );
};

export default TextBlock;

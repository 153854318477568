/**
 * Internal dependencies
 */
import Markdown from "components/markdown/markdown";

interface TestimonialProps {
  data: {
    Author: string;
    Position?: string;
    Content: string;
  };
}

const Testimonial: React.FC<TestimonialProps> = ({ data }) => {
  return (
    <div className="section-testimonial">
      <div className="container">
        <div className="section__inner">
          <div className="testimonial">
            <div className="testimonial__body">
              {data.Content && (
                <div className="testimonial__content">
                  <Markdown content={data.Content} />
                </div>
              )}

              <div className="testimonial__meta">
                {data.Author && (
                  <div className="testimonial__meta-author">{data.Author}</div>
                )}

                {data.Position && (
                  <div className="testimonial__meta-position">
                    {data.Position}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;

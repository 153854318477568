/**
 * External dependencies
 */
import { MediaProp } from "types";

/**
 * Internal dependencies
 */
import { ArticleTagsProps } from "types/article";
import ArticleContent from "../../components/articles/article-content";

interface SliderElementArticleProp {
  article: {
    article: {
      data: {
        attributes: {
          Image: MediaProp;
          Title: string;
          article_tags: ArticleTagsProps;
          slug: string;
          createdAt: Date;
        };
      };
    };
  };
}

const SliderElementArticle: React.FC<SliderElementArticleProp> = ({
  article: {
    article,
  },
}) => {
  if(!article?.data) {
    return null;
  }
  return (
    <div className="slider-element__slide slider-element__slide--article">
      <div className="slider-element__slide-inner">
        <ArticleContent data={article?.data} />
      </div>
    </div>
  )
};

export default SliderElementArticle;

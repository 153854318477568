import Image from "next/image";
import { MediaProp } from "types";
import { getStrapiMedia } from "utils";
import IconOnline2 from "../../assets/images/svg/icon-online2.svg";

interface TextImageProp {
  data: {
    OS2?: string;
    Title?: string;
    Content?: string;
    Image?: MediaProp;
  };
}

const TextImage: React.FC<TextImageProp> = ({ data }) => {
  const { OS2, Title, Content, Image: imageObj } = data;

  return (
    <div className="text-image">
      <div className="container container--small">
        <div className="text-image__inner">
          <div className="text">
            {OS2 && <IconOnline2 />}
            <h3>{Title}</h3>
            <p>{Content}</p>
          </div>
          <div className="image">
            {imageObj && imageObj.data && (
              <Image alt="" src={getStrapiMedia(imageObj)} layout="fill" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextImage;

import FeatureBoxes from "components/feature-boxes/feature-boxes";

interface FeaturesProp {
  boxes: {
    Heading: string;
    Content: string;
    Divider: boolean;
    FeatureBoxes: {
      Content: string;
      Media: any;
      Title: string;
      id: number;
    }[];
    Title: string;
    id?: number;
  };
}

const Features: React.FC<FeaturesProp> = ({ boxes }) => (
  <div className="features">
    <div className="container container--small">
      <div className="features__inner">
        {boxes.Heading && <h4>{boxes.Heading}</h4>}
        <FeatureBoxes boxes={boxes} />
      </div>
    </div>
  </div>
);

export default Features;

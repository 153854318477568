/**
 * External dependencies
 */
import Image from "next/image";

/**
 * Internal dependencies
 */
import { MediaProp } from "types";
import { getStrapiMedia } from "utils";
import Link from "components/link/link";

interface SliderElementImageProp {
  item: {
    Image: MediaProp;
    Link: string;
    RelNoFollow: boolean;
    OpenInNewTab: boolean;
  };
}

const SliderElementImage: React.FC<SliderElementImageProp> = ({ item }) => {
  const imageItem = (
    <div className="slider-element__slide slider-element__slide--image">
      {item.Image.data && (
        <Image
          alt=""
          src={getStrapiMedia(item.Image)}
          width={item.Image.data.attributes.width}
          height={item.Image.data.attributes.height}
          priority={true}
        />
      )}
    </div>
  );

  if (item.Link) {
    return <Link href={item.Link}>{imageItem}</Link>;
  }

  return imageItem;
};

export default SliderElementImage;

/**
 * External dependencies.
 */
import Image from "next/image";

/**
 * Internal dependencies
 */
import { getStrapiMediaArr } from "utils";
import { MediaArrProp } from "types";
import classNames from "classnames";

interface ImagesProps {
  data: {
    Images: {
      data: MediaArrProp[];
    };
    Layout: String;
    smaller?: boolean;
  };
}

const Images: React.FC<ImagesProps> = ({ data }) => (
  <div className="section-images">
    <div
      className={classNames("container", { "container--small": data.smaller })}
    >
      <div className="section__inner">
        <div
          className={`section__cols section__cols-images section__cols--${data.Layout.replace(
            " ",
            ""
          ).toLowerCase()}`}
        >
          {data.Images?.data?.map((item: MediaArrProp, index: number) => (
            <div className="section__col" key={index}>
              {item && (
                <Image
                  alt=""
                  src={getStrapiMediaArr(item)}
                  width={item.attributes.width}
                  height={item.attributes.height}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default Images;
